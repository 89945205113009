
import API from '@/api/manager/feedBack/feedBack'
import { sleep } from '@/utils/function'
import { encryptCode, decryptCode } from "@/utils/function";
import { request_sign } from '@/utils/const';
import VueUeditorWrap from "vue-ueditor-wrap";
import { editorUploadUrl } from "@/utils/const";
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
  name: "feedBack",
  inject: ['reload'],
  components: {
    VueUeditorWrap
  },
  data() {
    return {
      myConfig: {
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 初始容器高度
        initialFrameHeight: 250,
        // 你的UEditor资源存放的路径,相对于打包后的index.html
        UEDITOR_HOME_URL: "./Editor/",
        // 编辑器不自动被内容撑高
        // autoHeightEnabled: false,
        // 如果需要上传功能,找后端小伙伴要服务器接口地址
        serverUrl: editorUploadUrl,
        // 关闭自动保存
        enableAutoSave: false,
        // 自定义工具栏，需要额外选项可以参考ueditor.config.js
        toolbars: [
          [
            "inserttable",//表格
            "source", //源代码
            "bold", //加粗
            "italic", //斜体
            "underline", //下划线
            "strikethrough", //删除线
            "fontborder", //字符边框
            "blockquote", //引用
            "selectall", //全选
            "horizontal", //分隔线
            "removeformat", //清除格式
            "unlink", //取消链接
            "deletecaption", //删除表格标题
            "inserttitle", //插入标题
            "cleardoc", //清空文档
            "insertcode", //代码语言
            "fontfamily", //字体
            "fontsize", //字号
            "insertimage", //多图上传
            "link", //超链接
            "emotion", //表情
            "spechars", //特殊字符
            "searchreplace", //查询替换
            "insertvideo", //视频
            "justifyleft", //居左对齐
            "justifyright", //居右对齐
            "justifycenter", //居中对齐
            "forecolor", //字体颜色
            "insertorderedlist", //有序列表
            "insertunorderedlist", //无序列表
            "imageleft", //左浮动
            "imageright", //右浮动
            "attachment", //附件
            "imagecenter", //居中
            "lineheight", //行间距
          ],
        ],
        zIndex: 99999999
      },
      dialogVisible: false,
      content: '',
      feedbackList: [],
      // 未处理选中反馈信息id
      unhandlecheckList: [],
      unhandlecheckAllButton: false,
      // 已回复选中反馈信息id
      handlecheckList: [],
      handlecheckAllButton: false,
      // 预览问题图片链接
      previewquestionImg: [],
      // 0未处理,2已回复,-1已处理,-2软删除
      activeName: "0",
      // 虚拟实验列表
      expList: [],
      // 学校列表
      schoolList: [],
      // 学校id
      school_id: '',
      // 虚拟实验id
      exp_id: '',
      // 虚拟实验搜索值
      search: '',
      // 未处理消息列表
      unhandled_page: 1,
      unhandled_total: 50,
      unhandled_pagesize: 10,
      handled_page: 1,
      handled_total: 50,
      handled_pagesize: 10,
      delete_page: 1,
      delete_total: 50,
      delete_pagesize: 10,
      // 排行榜
      rankList: [],
      // 快捷回复关键词
      keywords: '',
      // 快捷回复加入问题库
      signSend: false
    };
  },
  /**
   * @name: 页面创建成功之后
   * @author: camellia
   * @date: 2021-03-04
   */
  created() {
    let self = this;
    if (self.$router.currentRoute.query.params) {
      let params = JSON.parse(decryptCode(self.$router.currentRoute.query.params));
      self.activeName = params?.activeName;;
      self.unhandled_page = Number(params?.unhandledPage);
      self.handled_page = Number(params?.handledPage);
      self.delete_page = Number(params?.deletePage);
      if (params?.schoolName) {
        self.schoolList.push({
          id: params?.schoolId,
          name: params?.schoolName,
        })
      }
      if (params?.expName) {
        self.expList.push({
          id: params?.expId,
          name: params?.expName,
        })
      }
      self.school_id = params?.schoolId;
      self.exp_id = params?.expId;
    }
    self.getSpitList();
  },
  /**
   * @name: 挂载成功之后
   * @author: camellia
   * @date: 2021-03-04
   */
  mounted() {
    let self = this;
    if (self.exp_id) {
      sleep(800).then(() => {
        self.updateCssStyle(self.exp_id);
      });
    }
  },
  methods: {
    /**
     * @name: 展示快捷回复弹窗
     * @author: LXY
     * @date: 2022-12-2 10:13:50
     */
    showdialogVisible() {
      this.content = '';
      if (this.unhandlecheckList?.length == 0) {
        return this.$Tips({
          // 消息提示内容
          message: '请至少选择一条反馈',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          displayTime: 1500
        })
      }
      this.dialogVisible = true;
    },
    /**
     * @name: 快捷回复
     * @author: LXY
     * @date: 2022-12-2 10:13:50
     */
    spitQuickReply() {
      let self = this;
      if (self.content == '') {
        return self.$Tips({
          // 消息提示内容
          message: '请输入快捷回复内容',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          displayTime: 1500
        })
      }
      if (self.signSend && !self.keywords) {
        return self.$Tips({
          // 消息提示内容
          message: '请输入关键词',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          displayTime: 1500
        })
      }
      let str = encryptCode({
        spit_id: self.unhandlecheckList.toString(),
        content: self.content,
        signSend: self.signSend,
        keywords: self.keywords
      })
      console.log({
        spit_id: self.unhandlecheckList.toString(),
        content: self.content,
        signSend: self.signSend,
        keywords: self.keywords
      });

      let data = { sign: request_sign, params: str }
      API.spitQuickReply(data)
        .then((result) => {
          if (result.code > 0) {
            self.unhandlecheckAllButton = false;
            self.unhandlecheckList = [];
            self.content = '';
            self.signSend = false;
            self.keywords = '';
            self.dialogVisible = false;
            self.getSpitList();
            self.$Tips({
              // 消息提示内容
              message: result.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'success',
              displayTime: 1500
            })
          }
          else {
            self.$Tips({
              // 消息提示内容
              message: result.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'warning',
              displayTime: 1500
            })
          }
        })
    },
    /**
     * @name: 获取editor示例
     * @author: LXY
     * @date: 2022-12-2 10:13:50
     */
    ready(editorInstance) {
      var self = this;
      editorInstance.focus();
      var str = "exam_paper_" + self.bank_id;
      // 添加自定义参数
      editorInstance.execCommand("serverparam", function (editor) {
        return {
          articleid: str //设置富文本编辑器请求时，携带的额外参数
        };
      });
    },
    /**
     * @name: 未处理消息全选
     * @author: lxy
     * @date: 20210908
     */
    unhandlecheckAll(e) {
      if (e) {
        this.feedbackList?.forEach((item) => {
          // console.log(item.id);
          this.unhandlecheckList.push(item.id)
        })
      }
      else {
        this.unhandlecheckList = []
      }
    },
    /**
     * @name: 未处理多选删除消息
     * @author: lxy
     * @date: 20210908
     */
    unhandledeleteMutile() {
      let self = this;
      if (self.unhandlecheckList?.length == 0) {
        return this.$Tips({
          // 消息提示内容
          message: '请至少选择一条反馈',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          displayTime: 1500
        })
      }
      let str = encryptCode({
        spit_id: self.unhandlecheckList.toString(),
      })
      let data = { sign: request_sign, params: str }
      self.$confirm(`确认要删除这些反馈吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        API.delSpitById(data)
          .then((result) => {
            if (result.code > 0) {
              self.unhandlecheckAllButton = false;
              self.getSpitList();
              self.$Tips({
                // 消息提示内容
                message: result.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                displayTime: 1500
              })
            }
            else {
              self.$Tips({
                // 消息提示内容
                message: result.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                displayTime: 1500
              })
            }
          })
      })
    },
    /**
     * @name: 已回复消息全选
     * @author: lxy
     * @date: 20210908
     */
    handlecheckAll(e) {
      if (e) {
        this.feedbackList?.forEach((item) => {
          this.handlecheckList.push(item.id)
        })
      }
      else {
        this.handlecheckList = []
      }
    },
    /**
    * @name: 已回复多选删除消息
    * @author: lxy
    * @date: 20210908
    */
    handledeleteMutile() {
      let self = this;
      if (self.handlecheckList?.length == 0) {
        return this.$Tips({
          // 消息提示内容
          message: '请至少选择一条反馈',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          displayTime: 1500
        })
      }
      let str = encryptCode({
        spit_id: self.handlecheckList.toString(),
      })
      let data = { sign: request_sign, params: str }
      self.$confirm(`确认要删除这些反馈吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        API.delSpitById(data)
          .then((result) => {
            if (result.code > 0) {
              self.handlecheckAllButton = false;
              self.getSpitList();
              self.$Tips({
                // 消息提示内容
                message: result.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                displayTime: 1500
              })
            }
            else {
              self.$Tips({
                // 消息提示内容
                message: result.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                displayTime: 1500
              })
            }
          })
      })
    },
    /**
     * @name: 未处理消息分页
     * @author: lxy
     * @date: 20210908
     */
    unhandled_change(e) {
      this.unhandled_page = e;
      this.unhandlecheckAllButton = false;
      this.unhandlecheckList = [];
      this.getSpitList();
    },
    /**
     * @name: 已回复消息分页
     * @author: lxy
     * @date: 20210908
     */
    handled_change(e) {
      this.handled_page = e;
      this.handlecheckAllButton = false;
      this.handlecheckList = [];
      this.getSpitList();
    },
    /**
     * @name: 已删除消息分页
     * @author: lxy
     * @date: 20210908
     */
    delete_change(e) {
      this.delete_page = e;
      this.getSpitList();
    },
    handleSearchColleague() { },
    /**
     * @name: 获取 意见反馈列表
     * @author: lxy
     * @date: 20210908
     */
    getSpitList() {
      let self = this;
      // 0未处理,2已回复,-1已处理,-2软删除
      let page = ''
      if (self.activeName === "0") {
        page = self.unhandled_page
      }
      else if (self.activeName === "2") {
        page = self.handled_page
      }
      else if (self.activeName === "-2") {
        page = self.delete_page
      }
      let schoolName = self.schoolList?.find((item) => {
        return item.id == self.school_id
      })?.name
      let expName = self.expList?.find((item) => {
        return item.id == self.exp_id
      })?.name
      let params = encryptCode({
        // 气体常数的测定
        expId: self.exp_id,
        expName: expName,
        unhandledPage: self.unhandled_page,
        handledPage: self.handled_page,
        deletePage: self.delete_page,
        schoolId: self.school_id,
        schoolName: schoolName,
        activeName: self.activeName
      })
      self.$router.push({
        path: "/manager/feedBack",
        query: {
          params: params
        },
      });

      let str = encryptCode({
        // 气体常数的测定
        exp_id: self.exp_id,
        page: page,
        pageSize: 10,
        school_id: self.school_id,
        state: self.activeName
      })
      let data = { sign: request_sign, params: str }
      nprogressStart();
      self.$Loading.show();
      API.getSpitList(data)
        .then((res) => {
          nprogressClose();
          self.$Loading.hide();
          if (res.code > 0) {
            self.feedbackList = res.list;
            self.feedbackList?.forEach((item) => {
              self.previewquestionImg.push(item.pic)
            })
            self.rankList = res.rankList;
            if (self.activeName === "0") {
              self.unhandled_total = res.total;
            }
            else if (self.activeName === "2") {
              self.handled_total = res.total;
            }
            else if (self.activeName === "-2") {
              self.delete_total = res.total;
            }
          }
          else {
            self.$Tips({
              // 消息提示内容
              message: result.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'warning',
              displayTime: 1500
            })
          }
        })
    },
    /**
     * @name: 获取虚拟实验列表
     * @author: lxy
     * @date: 20210908
     */
    getexpList(e) {
      let self = this;
      self.search = e
      // 请求参数
      let data = {
        search: self.search,
        school_id: self.school_id,
        page: 1,
        pagesize: 20
      };
      // 获取虚拟实验分类请求
      API.getExpList(data)
        .then((result) => {
          if (result.code > 0) {
            self.expList = result.logList
          }
          else {
            self.$Tips({
              // 消息提示内容
              message: result.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'warning',
              displayTime: 1500
            })
          }
        })
    },
    /**
     * @name: 获取学校列表
     * @author: LXY
     * @date: 2021-03-01
     */
    getschoolList(e) {
      let self = this;
      // 请求参数
      let data = {
        name: e,
        page: 1,
        pagesize: 10
      };
      // 获取学校列表请求
      API.getSchoolList(data)
        .then((result) => {
          if (result.code > 0) {
            self.schoolList = result.list
          }
          else {
            self.$Tips({
              // 消息提示内容
              message: result.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'warning',
              displayTime: 1500
            })
          }
        })
    },
    /**
     * @name: 回复消息
     * @author: LXY
     * @date: 2022-11-29
     */
    replay(id,name) {
      let self = this;
      let str = encryptCode({ 
        id: id,
        expname:name
      })
      sessionStorage.setItem('spit_id', id);
      sessionStorage.setItem('expname', name);
      window.open('#/manager/feedBack/replay?params=' + str)
    },
    /**
     * @name: 删除消息记录
     * @author: LXY
     * @date: 2022-11-29
     */
    delcontact(id) {
      let self = this;
      // 请求参数
      let str = encryptCode({
        spit_id: id,
      })
      let data = { sign: request_sign, params: str }
      // 删除同事请求
      self.$confirm(`确认要删除此反馈吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        API.delSpitById(data)
          .then((result) => {
            if (result.code > 0) {
              // Message.success('删除成功');
              self.getSpitList();
              self.$Tips({
                // 消息提示内容
                message: result.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                displayTime: 1500
              })
            }
            else {
              self.$Tips({
                // 消息提示内容
                message: result.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                displayTime: 1500
              })
            }
          })
      })
    },
    tabHandleClick(e) {
      // console.log(this.activeName);
      this.getSpitList();
    },
    /**
     * @name: 点击左侧虚拟实验列表
     * @author: LXY
     * @date: 2022-12-04
     */
    selectExp(exp_id, exp_name) {
      this.exp_id = exp_id
      this.expList.push(
        {
          id: exp_id,
          name: exp_name
        }
      )
      this.updateCssStyle(exp_id)
      this.getSpitList();
    },
    /**
     * @name: 更改css样式
     * @author: camellia
     * @date: 2021-03-03
     * @param:	id	type	description
     */
    updateCssStyle(id) {
      // css效果
      var d = document.querySelectorAll(".left-module tr");
      for (var p = d.length; p--;) {
        if (d[p].id != id) {
          d[p].style.backgroundColor = "#FFFFFF"; /*其他*/
          d[p].classList.remove("selectTr");
        } else {
          d[p].style.backgroundColor = "#E5ECF1"; /*点击的*/
          d[p].classList.add("selectTr");
        }
      }
    },

  },
};
