<template>
  <div class="feedBack">
    <!-- 左侧模块 -->
    <div >
      <div class="left-module">
        <div class="filterBox">
          <div class="filterItem">
            <el-select
              v-model="school_id"
              filterable
              remote
              clearable
              placeholder="请搜索选择学校"
              :remote-method="getschoolList"
              @clear="getSpitList"
            >
              <el-option
                v-for="item in schoolList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="filterItem">
            <el-select
              v-model="exp_id"
              filterable
              remote
              clearable
              placeholder="请搜索选择虚拟实验"
              @clear="getSpitList"
              :remote-method="getexpList"
            >
              <el-option v-for="item in expList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div>
            <el-button class="themeButton" @click="getSpitList">筛选</el-button>
          </div>
        </div>
        <!-- 虚拟实验列表 -->
        <div class="projectListBox">
          <table>
            <tr
              :id="item.exp_id"
              v-for="(item, index) in rankList"
              :key="item.exp_id"
              @click="selectExp(item.exp_id,item.name)"
            >
              <!-- 项目名称 -->
              <td class="td-project">{{item.name}}({{item.spitNum}})</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <!-- 右侧模块 -->
    <div class="right-module">
      <el-tabs v-model="activeName" @tab-click="tabHandleClick">
        <el-tab-pane label="未处理" name="0">
          <div class="setDetail">
            <el-checkbox-group v-model="unhandlecheckList">
              <div class="detail" v-for="item in feedbackList" :key="item.id">
                <div class="info">
                  <div>
                    <el-checkbox :label="item.id">{{item.expname}}</el-checkbox>
                  </div>
                  <div class="step">
                    <div>CID：{{item.CID}}</div>
                    <div class="text">STEP：{{item.step}}</div>
                    <el-dropdown trigger="click">
                      <span class="el-dropdown-link">
                        <i class="el-icon-more"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                          <div class="repaly_item" @click="replay(item.id,item.expname)">回复</div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <div class="repaly_item" @click="delcontact(item.id)">删除</div>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
                <div class="question">
                  <div>
                    <el-image
                      v-if="item.pic"
                      :src="item.pic"
                      :preview-src-list="previewquestionImg"
                    ></el-image>
                  </div>
                  <div class="info_box">
                    <div class="info_item">姓名：{{item.name}}</div>
                    <div class="info_item">学校：{{item.schoolName}}</div>
                    <div class="info_item">电话：{{item.tel}}</div>
                    <br />
                    <div class="info_item">版本号：{{item.clientversion}}</div>
                    <div class="info_item">设备型号：{{item.phonetype}}</div>
                    <div class="info_item">提交时间：{{item.subtime}}</div>
                    <div class="record">
                      <div v-html="item.spit"></div>
                      <div v-html="item.lastSpit"></div>
                    </div>
                  </div>
                </div>
              </div>
            </el-checkbox-group>
          </div>
          <div class="feedbackoptaterBox">
            <el-checkbox @change="unhandlecheckAll" v-model="unhandlecheckAllButton"></el-checkbox>全选
            <el-button class="themeButton" @click="unhandledeleteMutile">删除</el-button>
            <el-button class="themeButton" @click="showdialogVisible">快捷回复</el-button>
          </div>
          <div class="pagination">
            <el-pagination
              @current-change="unhandled_change"
              background
              layout="prev, pager, next"
              :current-page.sync="unhandled_page"
              :total="unhandled_total"
              :page-size="unhandled_pagesize"
              v-if="unhandled_total != 0"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已回复" name="2">
          <div class="setDetail">
            <el-checkbox-group v-model="handlecheckList">
              <div class="detail" v-for="item in feedbackList" :key="item.id">
                <div class="info">
                  <div>
                    <el-checkbox :label="item.id">{{item.expname}}</el-checkbox>
                  </div>
                  <div class="step">
                    <div>CID：{{item.CID}}</div>
                    <div class="text">STEP：{{item.step}}</div>
                    <el-dropdown trigger="click">
                      <span class="el-dropdown-link">
                        <i class="el-icon-more"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                          <div class="repaly_item" @click="replay(item.id,item.expname)">回复</div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <div class="repaly_item" @click="delcontact(item.id)">删除</div>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <!-- <div>
                      <el-dropdown>
                          <span class="el-dropdown-link">
                            <i class="el-icon-more"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>回复</el-dropdown-item>
                            <el-dropdown-item>删除</el-dropdown-item>
                          </el-dropdown-menu>
                      </el-dropdown>
                  </div>-->
                </div>
                <div class="question">
                  <div>
                    <el-image
                      v-if="item.pic"
                      :src="item.pic"
                      :preview-src-list="previewquestionImg"
                    ></el-image>
                  </div>
                  <div class="info_box">
                    <div class="info_item">姓名：{{item.name}}</div>
                    <div class="info_item">学校：{{item.schoolName}}</div>
                    <div class="info_item">电话：{{item.tel}}</div>
                    <br />
                    <div class="info_item">版本号：{{item.clientversion}}</div>
                    <div class="info_item">设备型号：{{item.phonetype}}</div>
                    <div class="info_item">提交时间：{{item.subtime}}</div>
                    <div class="record">
                      <div v-html="item.spit"></div>
                      <div v-html="item.lastSpit"></div>
                    </div>
                  </div>
                </div>
              </div>
            </el-checkbox-group>
          </div>
          <div class="feedbackoptaterBox">
            <el-checkbox @change="handlecheckAll" v-model="handlecheckAllButton"></el-checkbox>全选
            <el-button class="themeButton" @click="handledeleteMutile">删除</el-button>
          </div>
          <div class="pagination">
            <el-pagination
              @current-change="handled_change"
              background
              layout="prev, pager, next"
              :current-page.sync="handled_page"
              :total="handled_total"
              :page-size="handled_pagesize"
              v-if="handled_total != 0"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已删除" name="-2">
          <div class="setDetail">
            <div class="detail" v-for="item in feedbackList" :key="item.id">
              <div class="info">
                <div>{{item.expname}}</div>
                <div class="step">
                  <div>CID：{{item.CID}}</div>
                  <div class="text">STEP：{{item.step}}</div>
                </div>
              </div>
              <div class="question">
                <div>
                  <el-image :src="item.pic" :preview-src-list="previewquestionImg"></el-image>
                </div>
                <div class="info_box">
                  <div class="info_item">姓名：{{item.name}}</div>
                  <div class="info_item">学校：{{item.schoolName}}</div>
                  <div class="info_item">电话：{{item.tel}}</div>
                  <br />
                  <div class="info_item">版本号：{{item.clientversion}}</div>
                  <div class="info_item">设备型号：{{item.phonetype}}</div>
                  <div class="info_item">提交时间：{{item.subtime}}</div>
                  <div class="record">
                    <div v-html="item.spit"></div>
                    <div v-html="item.lastSpit"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination">
            <el-pagination
              @current-change="delete_change"
              background
              layout="prev, pager, next"
              :current-page.sync="delete_page"
              :total="delete_total"
              :page-size="delete_pagesize"
              v-if="delete_total != 0"
            ></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 快捷回复弹窗 -->
    <div>
      <el-dialog title="快捷回复" :visible.sync="dialogVisible" width="50%">
        <vue-ueditor-wrap v-model="content" :config="myConfig" @ready="ready" style="z-index:3000"></vue-ueditor-wrap>
        <div class="opt">
          <el-input placeholder="请输入关键词" v-model="keywords"></el-input>
          <el-checkbox v-model="signSend" label="加入问题库" border></el-checkbox>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="themecancelButton" @click="dialogVisible = false">取 消</el-button>
          <el-button class="themeButton" @click="spitQuickReply">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<style lang="scss">
  .right-module{
      width:78.5% !important;
  }
  .feedBack .el-tabs__item.is-active {
    color: #0d5570 !important;
    border-radius: 0.2083vw;
  }
  .feedBack .el-tabs__content {
    overflow: visible;
  }
  .feedBack .el-tabs__active-bar {
    height: 0.2083vw;
    border-radius: 0.2083vw;
    background-color: #0d5570;
  }
  .feedBack .el-tabs__nav-wrap::after {
    content: "";
    height: 0.2083vw;
    border-radius: 0.2083vw;
    z-index: 0 !important;
  }
  .feedBack .el-tabs__item {
    padding: 0 1.0417vw;
    // margin-right: 1.25rem;
    height: 2.0833vw;
    // width: 4.375vw;
    text-align: center;
    font: normal normal normal 0.8333vw Source Han Sans CN;
  }
  .feedBack .el-tabs {
    font: normal normal normal 0.8333vw Source Han Sans CN !important;
  }
  .feedBack {
    // .el-select{
    .el-input__inner {
      height: 2.0833vw;
      font-size: 0.833vw;
      // }
    }
  }
  .feedBack .el-checkbox-group {
    font-size: 0.833vw;
  }
  .feedBack .el-dropdown {
    cursor: pointer;
    margin-left: 1vw;
  }
  .feedBack .el-checkbox__label {
    font-size: 0.8333vw;
  }
  .feedBack .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0d5570;
    color: #fff;
  }
  .feedBack .el-dialog {
    border-radius: 1vw;
  }
  .feedBack .el-dialog__body {
    height: 50vh;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .feedBack .el-dialog__body::-webkit-scrollbar {
    display: none;
  }
  .feedBack .el-checkbox__input.is-checked .el-checkbox__inner,
  .feedBack .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #0d5570;
    border-color: #0d5570;
  }
  .feedBack .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #0d5570;
  }
</style>
<style lang="scss" scoped>
  @import "@/assets/css/manager/feedBack/feedBack.scss";
</style>
<script>
  import feedBack from "@/assets/js/manager/feedBack/feedBack.js";
  export default {
    ...feedBack
  };
</script> 